import React, { Fragment } from 'react'
import styled from 'styled-components'

import Button1 from 'components/Button'

export default () => (
  <Fragment>
    <Heading>BUTTONS</Heading>
    <Divider />
    <Buttons>
      <Button1 url="/__style-sheet">Button 1</Button1>
    </Buttons>
  </Fragment>
)
const Heading = styled.h2`
  font-size: 18pt;
  font-weight: ${props => props.theme.ultra};
  color: ${props => props.theme.primaryColor};
  letter-spacing: 2px;
`
const Divider = styled.div`
  margin: 10px 0 20px;
  width: 55px;
  height: 4px;
  background: ${props => props.theme.primaryColor};
`
const Buttons = styled.div`
  margin: 0 0 30px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`
