import React, { Fragment } from 'react'
import styled from 'styled-components'

export default () => (
  <Fragment>
    <Heading>COLORS</Heading>
    <Divider />
    <Colors>
      <ColorCard>
        <PrimaryCard />
        <PrimaryTitle>Primary Color</PrimaryTitle>
        <ColorCode>#000000</ColorCode>
      </ColorCard>
      <ColorCard>
        <HeadingCard />
        <HeadingTitle>Heading Color</HeadingTitle>
        <ColorCode>#000000</ColorCode>
      </ColorCard>
      <ColorCard>
        <FontCard />
        <FontTitle>Font Color</FontTitle>
        <ColorCode>#4A4A4A</ColorCode>
      </ColorCard>
      <ColorCard>
        <ButtonCard />
        <ButtonTitle>Button Color</ButtonTitle>
        <ColorCode>#000000</ColorCode>
      </ColorCard>
    </Colors>
  </Fragment>
)
const Heading = styled.h2`
  font-size: 18pt;
  font-weight: ${props => props.theme.ultra};
  color: ${props => props.theme.primaryColor};
  letter-spacing: 2px;
`
const Divider = styled.div`
  margin: 10px 0 20px;
  width: 55px;
  height: 3px;
  background: ${props => props.theme.primaryColor};
`
const ColorCard = styled.div`
  margin: 10px;
  width: 150px;
  height: 220px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.25);

  &:first-of-type {
    margin: 10px 10px 10px 0;
  }

  &:last-of-type {
    margin: 10px 0 10px 10px;
  }
`
const Color = styled.div`
  width: 100%;
  height: 120px;
  margin: 0 0 10px;
`
const Title = styled.h2`
  margin: 0 0 10px;
  font-size: 14pt;
  font-weight: ${props => props.theme.bold};
`
const ColorCode = styled.p`
  font-size: 14pt;
  font-weight: ${props => props.theme.demi};
`
const Colors = styled.div`
  margin: 0 0 30px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const PrimaryCard = styled(Color)`
  background-color: ${props => props.theme.primaryColor};
`
const PrimaryTitle = styled(Title)`
  color: ${props => props.theme.primaryColor};
`
const HeadingCard = styled(Color)`
  background-color: ${props => props.theme.headingColor};
`
const HeadingTitle = styled(Title)`
  color: ${props => props.theme.headingColor};
`
const FontCard = styled(Color)`
  background-color: ${props => props.theme.fontColor};
`
const FontTitle = styled(Title)`
  color: ${props => props.theme.fontColor};
`
const ButtonCard = styled(Color)`
  background-color: ${props => props.theme.buttonColor};
`
const ButtonTitle = styled(Title)`
  color: ${props => props.theme.buttonColor};
`
