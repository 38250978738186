import React, { Fragment } from 'react'
import styled from 'styled-components'

export default () => (
  <Fragment>
    <Heading>FONTS</Heading>
    <Divider />
    <Fonts>
      <HeadingFont>Source Sans Pro | Ultra</HeadingFont>
      <Label>Headings</Label>
      <SubtitleFont>Source Sans Pro | Bold</SubtitleFont>
      <Label>Subtitles</Label>
      <BodyFont>Source Sans Pro | Demi</BodyFont>
      <Label>Body Font</Label>
    </Fonts>
  </Fragment>
)
const Heading = styled.h2`
  font-size: 18pt;
  font-weight: ${props => props.theme.ultra};
  color: ${props => props.theme.primaryColor};
  letter-spacing: 2px;
`
const Divider = styled.div`
  margin: 10px 0 20px;
  width: 55px;
  height: 4px;
  background: ${props => props.theme.primaryColor};
`
const Fonts = styled.div`
  margin: 0 0 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`
const Label = styled.div`
  margin: 5px 0 30px;
  padding: 12px 15px;
  background: rgba(0, 0, 0, 0.15);
  text-transform: uppercase;
  line-height: 0;
  color: white;
  font-size: 10pt;
  border-radius: 12px;

  &:last-of-type {
    margin: 5px 0 10px;
  }
`
const HeadingFont = styled.h1`
  font-size: 46pt;
  color: ${props => props.theme.primaryColor};
  font-family: ${props => props.theme.fontFamily};
  font-weight: ${props => props.theme.ultra};
`
const SubtitleFont = styled.h4`
  font-size: 22pt;
  color: ${props => props.theme.primaryColor};
  font-family: ${props => props.theme.fontFamily};
  font-weight: ${props => props.theme.bold};
`
const BodyFont = styled.p`
  font-size: 16pt;
  color: ${props => props.theme.primaryColor};
  font-family: ${props => props.theme.fontFamily};
  font-weight: ${props => props.theme.demi};
`
