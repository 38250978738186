import React from 'react'
import { graphql } from 'gatsby'
import StyleSheet from './StyleSheet'
import Colors from './Colors'
import Fonts from './Fonts'
import Buttons from './Buttons'
import NavBar from 'components/NavBar'
import Layout from 'components/Layout'

export const StyleSheetTemplate = ({ title }) => (
  <Layout>
    <NavBar />
    <StyleSheet title={title}>
      <Colors />
      <Fonts />
      <Buttons />
    </StyleSheet>
  </Layout>
)

export default ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return <StyleSheetTemplate title={frontmatter.title} />
}

export const query = graphql`
  query StyleSheetQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
      }
    }
  }
`
