import React from 'react'
import styled from 'styled-components'

export default ({ title, children }) => (
  <Container>
    <Title>{title}</Title>
    {children}
  </Container>
)
const Container = styled.div`
  width: 100%;
  padding: 100px 100px 40px;
`
const Title = styled.h1`
  margin: 0 0 30px;
  width: 100%;
  font-size: 26pt;
  color: ${props => props.theme.fontColor};
`
